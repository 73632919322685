:root {

  --bg-body-color: #C5C5C5;
  --body-text-color: #171717;
  --nav-content-color: #DFE0E2;
  --button-input-bg-color: #C5C5C5;
  --button-input-text-color: maroon;
  --table-data-text-color: maroon;

  /* --bg-body-color: #F45050;
  --body-text-color: #171717;
  --nav-content-color: #F9D949;
  --button-input-bg-color: #C5C5C5;
  --button-input-text-color: #3C486B;
  --table-data-text-color: #213555; */

}

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  box-sizing: border-box;
  /* background-color: #DFE0E2; */
  /* color: #171717; */
}


body {
  /* background-color: yellow; */
  /* background-color: #DFE0E2;  */
  background-color: var(--bg-body-color);
  color: var(--body-text-color);
}

.shorttitle {
  display: none;
}

/* duck logo in header */
img {
  height: 150px;
  margin: 0 20px;
}

.navcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  background-color: var(--nav-content-color);
  /* background-color: #DFE0E2; */
  /* color: #171717; */
}

.navcontainer ul {
  display: flex;
}

.navcontainer li {
  /* margin: 0 20px; */
  list-style: none;
}

.navcontainer li a, .navcontainer .header-divs a {
  text-decoration: none;
  color: var(--body-text-color);
  /* font-size: 20px; */
}

.navcontainer li:hover {
  cursor: pointer;
}

.left-menu h1 {
  border-bottom: 1px solid black;
  padding-right: 40px;
}

.right-menu h1 {
  border-bottom: 1px solid black;
  padding-left: 40px;
}

.header-divs {
  /* border: 1px solid red; */
  text-align: center;
}

.header-divs ul {
  /* color: red; */
  /* border: 2px solid yellow; */
  /* text-align: right; */
  display: flex;
  justify-content: center;
}


/*  content css  */
.content {
  background-color: var(--nav-content-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  margin: 20px auto;
  padding: 50px;
  border-radius: 15px;
  min-height: calc(100vh - 282px);
  width: 50%;
  font-size: 18px;
}

.content p {
  margin: 0 0 20px 0;
}

.findmyduck {
  text-align: center;
}

#findmyduck, #saveducklocation, #duckCode, #duckCity, #duckState {
  background-color: var(--button-input-bg-color);
  /* color: #171717; */
  /* color: #B77672; */
  /* color: #17A6B6; */
  /* color: maroon; */
  color: var(--button-input-text-color);
  font-weight: bold;
  padding: 10px;
}

.findmyduck input, .addLocation input, button {
  /* border: none; */
  border-radius: 15px;
}

.addLocationMsg {
  text-align: center;
  /* color: red; */
  /* background-color: orange; */
  /* border: 1px solid black; */
}

.addLocationMsg label {
  color: maroon;
  color: var(--button-input-text-color);
}

/* This displays the randomly generated duck ID on /generate */
#duckid {
  font-size: 36px;
}

.generateid {
  text-align: center;
  color: red;
}


/* Footer */
footer {
  font-family: Nunito;
  /* display: flex; */
  /* justify-content: space-between; */
  /* background-color: yellow; */
  /* color: yellow; */
  padding: 25px 20px;
  font-size: 12px;
  position: relative;
  left: 0;
  bottom: 0;
}

footer p {
  margin: 0;
  padding: 0;
}

.footerInfo, .disclaimer {
  text-align: right;
}

/* #company, #companycity {
  background-color: blue;
  font-size: 360px;
} */


/* duck ID data */
table {
  width: 100%;
  table-layout: fixed;
  /* border: 1px solid black; */
  font-size: 12px;
}

table th {
  text-align: left;
}

td {
  word-wrap: break-word;
}

th, td {
  color: maroon;
  color: var(--table-data-text-color);
}

.addLocation {
  margin: 20px 0 0 0;
}

.addLocation input, .addLocation button, .findmyduck input, .findmyduck button {
  margin: 5px;
  padding: 5px 10px;
}

@media screen and (max-width: 1024px) {
  .content {
    width: 80%;
  }

  .container {
    padding: 10px;
  }

  .navcontainer ul {
    display: flex;
    flex-direction: column;
  }

  .addLocation {
    display: flex;
    flex-direction: column;
  }

  #city, #state, #duckCode {
    padding: 20px;
    font-size: 20px;
  }

  .addLocation input, .addLocation button, .findmyduck input, .findmyduck button {
    font-size: 20px;
    margin: 10px 0;
    padding: 25px 0;
    width: 100%;
  }
  
}


@media screen and (max-width: 768px) {

  .shorttitle {
    display: inline-block;
  }

  .ducklogo {
    width: 50%;
    height: auto;
    /* background-color: black; */
  }
  
  .fulltitle {
    display: none;
  }

  .content {
    width: 100%;
    padding: 25px;
    text-align: justify;
  } 

  .header-divs {
    flex: 1;
  }
  .navcontainer {
    /* display: flex; */
    /* justify-content: center; */

  }

.navcontainer .header-divs h1 {
    font-size: 18px;
    margin: 10px 0 0 0;
    border-bottom: 0px solid black;
    padding-left: 0;
  }
}